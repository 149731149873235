// <!-- rotating values -->
// <div class="bx-cont">
//   <ul id="bxslider">
//     <li>What do you love about school?</li>
//     <li>Insert your quotes here.</li>
//     <li>Press ENTER to add a new quote.</li>
//   </ul>
// </div>

// // ------------------------------ Bx Slider
// $('#bxslider').bxSlider({
// 	mode: 'fade',
// 	auto: true,
// 	pager: true,
// 	controls: false,
// 	// slideWidth: 500,
// 	autoControls: false,
// 	pause: 7000
// });

// BXSLIDER

.bx-cont {
  // margin: 0 auto;
  max-width: 880px;
  text-align: center;
  color: #fff;

  ul#bxslider {
    margin: 70px 0 0 0;

    li {
      padding: 0;
      background: none;
      text-align: left;
      max-width: 100%;
      margin: 0 auto;
      color: #FFF;
      font-size: 26px;
      line-height: 30px;

      @media only screen and (max-width: 990px) {
        text-align: center;
        font-size: 22px;
      }

      @media only screen and (max-width: 990px) {
        font-size: 19px;
        line-height: 1;
      }

      span {
        margin: 40px 0 0 0;
        display: block;
        font-size: 18px;
      }
    }
  }
}

.bx-viewport {
  overflow: visible !important;
}

// BXSLIDER CONTROLS - INDICATORS

// .bx-controls{ display: block; text-align: center; margin: 30px auto; border-radius: 50%;

//   .bx-pager-item { display: inline-block; height: 20px; width: 20px; margin: 0 8px; border-radius: 50%;

//     .bx-pager-link { height: 20px; width: 20px; background: #947cb3; overflow: hidden;color: transparent; display: inline-block; margin: 0 auto; border-radius: 50%;}

//       .active { background: #344055;}
//   }
// }

// BXSLIDER CONTROLS - ARROWS

// .bx-prev {position: absolute; color: transparent; left: 0; top: 250px; width: 28px; height: 40px; background: url(/i/design/news-left.png) no-repeat center; background-size: contain;}

// .bx-next {position: absolute; color: transparent; right: 0; top: 250px; width: 28px; height: 40px; background: url(/i/design/news-right.png) no-repeat center; background-size: contain;}


// for c_import in the CMS

// [bx-container] {margin: 50px auto; left: 0; right: 0; top: 0;}
// [bx-container]  li {border: 5px dashed #fff; background: #222!important; padding: 10px; color: #fff!important;}