section#social {
	width: 220px;
	height: 270px !important;
	position: fixed;
	top: calc(50% - 200px);
	left: auto;
	visibility: hidden;
	background: rgba(#003961, 0.8);
	z-index: 80;
	box-shadow: inset -100px 0px 0px -90px #E1BE00;

	@media only screen and (max-width: 1000px) {
		visibility: hidden !important;
		display: none !important;
	}

	// Right sidebar
	#sidebar {
		left: -60px;
		top: 0;
		height: 270px;
		width: 60px;
		z-index: 40;
		position: absolute;
		transition: all 0.2s ease-out;
		cursor: pointer;
		background: rgba(#003961, 0.8);

		h2 {
			transform: rotate(90deg);
			text-align: center;
			position: absolute;
			left: -110%;
			top: 115px;
			color: #fff;
			margin: 0;
			font-size: 24px;
			font-family: 'webfontregular', Georgia, serif;
			text-transform: uppercase;
			white-space: nowrap;
		}

		&:hover {
			cursor: pointer;
		}
	}
}