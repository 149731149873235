// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//



// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #003961;
$h2-color: #E2BE00;
$h3-bg-color: #003961;
$h4-bg-color: #E2BE00;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	// font-family: 'webfontregular', Georgia, serif;
	font-family: 'Gill Sans MT', Calibri, sans-serif;
}

.body_1327 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_1327 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
	font-family: 'webfontregular', Georgia, serif;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
	font-weight: lighter;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_1327 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
	width: 88%;

	@media only screen and (max-width: 990px) {
		width: 100%;
	}
}

header h1 {
	margin: 0;
	background: none;
}

#logo-index {
	margin: 0;
	padding: 0;
	position: relative;
	top: 100px;
	width: fit-content;

	@media only screen and (max-width: 990px) {
		top: 150px;
	}

	a {
		display: flex;
		align-items: center;

		@media only screen and (max-width: 990px) {
			flex-direction: column;
			gap: 15px;
		}

		img#logo {}

		h2 {
			color: #fff;
			text-align: left;
			font-size: 24px;
			line-height: 30px;
			text-transform: uppercase;
			margin: 0 0 0 15px;

			@media only screen and (max-width: 990px) {
				text-align: center;
				margin: 0;
			}
		}
	}
}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 0px;
	top: 85px;
	z-index: 99;

	@media only screen and (max-width: 990px) {
		left: 0;
		right: 0;
		top: 110px;
		pointer-events: none;
	}

	li {
		display: inline-block;
		@include box(21px);
		padding: 0;
		background: none;
		margin: 0 5px;
		pointer-events: visible;
		transition: 0.4s;

		&:hover {
			scale: 1.1;
		}

		a {
			display: block;
			@include box(100%);
		}

		//	#facebook-head { @include box(21px); overflow: hidden; z-index: 99; background: url(/i/design/fb-ic.png) no-repeat center; } 
		//	#twitter-head { @include box(21px); overflow: hidden; z-index: 99; background: url(/i/design/tw-ic.png) no-repeat center; } 
		//	#instagram-head { @include box(21px); overflow: hidden; z-index: 99; background: url(/i/design/in-ic.png) no-repeat center; } 
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(21px);
	overflow: hidden;
	background: url(/i/design/translate-ic.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(21px);
	background: url(/i/design/search-ic.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// TRUST TAB
// -----------------------------------//

#trust-tab {
	position: absolute;
	top: -270px;
	left: 140px;
	width: 175px;
	background: #fff;
	transition: 0.5s;

	@media only screen and (max-width: 1220px) {
		left: -10px;
	}

	// @media only screen and (max-width: 1150px) {
	// 	left: 10px;
	// }

	@media only screen and (max-width: 1030px) {
		left: -62px;
	}

	@media only screen and (max-width: 990px) {
		top: -180px;
		left: 0;
	}

	@media only screen and (max-width: 560px) {
		display: none;
	}

	.content {
		font-size: 14px;
		padding: 15px 10px 0px 10px;
	}

	.tab {
		padding: 0;

		img {
			cursor: pointer;
		}
	}
}

.reveal-trust {
	top: 0px !important;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 70px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
	position: relative;
	z-index: 10;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/welcome-watermark.png);
		height: 673px;
		width: 627px;
		left: 100px;
		top: 100px;
		opacity: 0.6;
	}
}

// home page alternate
.body_1327 #mainholder {
	padding: 0;
	margin: 0;
	position: relative;
	z-index: 10;

	&:before {
		display: none;
	}
}

// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

#welcome-sec {
	padding: 100px 0;
	background: #fff;
	position: relative;

	&::before {
		position: absolute;
		content: "";
		background: url(/i/design/welcome-watermark.png);
		height: 673px;
		width: 627px;
		left: 50px;
		top: 70px;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	.wrapper {
		width: 1160px;

		@media only screen and (max-width: 1420px) {
			width: 85%;
		}

		.flex-wrapper {
			position: relative;
			left: 100px;
			display: flex;
			align-items: flex-start;

			@media only screen and (max-width: 1420px) {
				left: auto;
			}

			@media only screen and (max-width: 990px) {
				flex-direction: column-reverse;
				align-items: center;
				gap: 40px;
			}


			.left {
				position: relative;
				flex-shrink: 0;
				margin: 0 50px 0 0;

				@media only screen and (max-width: 990px) {
					margin: 0;
				}

				img {
					position: relative;
					top: 20px;

					@media only screen and (max-width: 990px) {
						top: auto;
					}
				}
			}

			.right {

				@media only screen and (max-width: 990px) {
					text-align: center;
				}

				h2 {
					color: #E2BE00;
					font-size: 4.427vw;
					text-transform: uppercase;
					// margin: 0 0 20px 0;
					margin: 0;

					@media only screen and (max-width: 990px) {
						font-size: 45px;
					}
				}

				.welcome-sub-head {
					color: #003863;
					font-size: 1.5vw;
					margin: 0 0 50px 0;
					font-family: 'webfontregular', Georgia, serif;

					@media only screen and (max-width: 990px) {
						font-size: 28px;
					}

					span {
						display: block;
						font-size: 20px;

						@media only screen and (max-width: 990px) {
							font-size: 20px;
						}
					}
				}

				p {
					font-size: 20px;
					font-weight: lighter;
					color: #727272;
					-webkit-font-smoothing: antialiased;
				}

				.head {
					font-family: 'webfontregular', Georgia, serif;
					font-size: 14px;
					font-weight: bold;
					padding: 20px 0 0 0;
				}

				.read-more {
					margin: 40px 0 0 0;
					display: block;
					font-size: 14px;
					font-weight: bold;
					font-family: 'webfontregular', Georgia, serif;
					width: 270px;
					height: 60px;
					border: 1px solid #003863;
					transition: 0.3s;

					@media only screen and (max-width: 990px) {
						margin: 0 auto;
					}

					&:hover {
						background: #003863;

						span {
							color: #fff;
						}
					}

					span {
						display: block;
						line-height: 60px;
						text-align: center;
						color: #003863;
						text-transform: uppercase;
					}
				}
			}
		}
	}
}

// -----------------------------------//
// HOME BUTTONS SEC
// -----------------------------------//

#home-btns {
	position: relative;
	font-family: 'webfontregular', Georgia, serif;

	ul {
		display: flex;
		max-height: 309px;

		@media only screen and (max-width: 1100px) {
			flex-wrap: wrap;
			max-height: none;
		}

		li {
			position: relative;
			background: none;
			padding: 0;
			margin: 0;
			width: calc(100%/4);
			transition: 0.4s;
			overflow: hidden;

			&:hover {


				a {

					img {
						scale: 1.2;

						&:nth-of-type(1) {
							filter: brightness(110%);
							mix-blend-mode: hard-light;
						}
					}
				}
			}

			@media only screen and (max-width: 1100px) {
				width: calc(100% / 2);
				overflow: hidden;
			}

			@media only screen and (max-width: 530px) {
				width: 100%;
			}

			&:nth-of-type(1) {
				a {
					&:after {
						position: absolute;
						content: "";
						box-shadow: inset 0px -100px 0px -90px #008944;
						width: 100%;
						height: 10px;
						bottom: 0;
						z-index: 3;
					}
				}
			}

			&:nth-of-type(2) {
				a {
					&:after {
						position: absolute;
						content: "";
						box-shadow: inset 0px -100px 0px -90px #4184B2;
						width: 100%;
						height: 10px;
						bottom: 0;
						z-index: 3;
					}
				}
			}

			&:nth-of-type(3) {
				a {
					&:after {
						position: absolute;
						content: "";
						box-shadow: inset 0px -100px 0px -90px #A32900;
						width: 100%;
						height: 10px;
						bottom: 0;
						z-index: 3;
					}
				}
			}

			&:nth-of-type(4) {
				a {
					&:after {
						position: absolute;
						content: "";
						box-shadow: inset 0px -100px 0px -90px #E2BE00;
						width: 100%;
						height: 10px;
						bottom: 0;
						z-index: 3;
					}
				}
			}

			a {
				position: relative;

				@media only screen and (max-width: 1100px) {
					height: 230px;
					display: block;
				}

				@media only screen and (max-width: 530px) {
					height: 190px;
				}

				@media only screen and (max-width: 590px) {
					&:before {
						position: absolute;
						content: "";
						background: rgba(#003863, 0.7);
						width: 100%;
						height: 100%;
						bottom: 0;
						left: 0;
						right: 0;
						z-index: 1;
					}
				}

				img {
					transition: 0.3s;

					&:nth-of-type(1) {
						position: relative;
						object-fit: cover;

						@media only screen and (max-width: 1100px) {
							width: 100%;
							height: 100%;
						}
					}

					&:nth-of-type(2) {
						position: absolute;
						bottom: 0;

						@media only screen and (max-width: 1100px) {
							width: 100%;
						}

						@media only screen and (max-width: 590px) {
							display: none;
						}
					}
				}

				span {
					width: 100%;
					text-align: center;
					position: absolute;
					bottom: 25px;
					font-size: 30px;
					color: #fff;
					z-index: 2;

					@media only screen and (max-width: 1660px) {
						bottom: 13px;
					}

					@media only screen and (max-width: 1430px) {
						font-size: 26px;
					}

					@media only screen and (max-width: 1200px) {
						font-size: 24px;
					}

					@media only screen and (max-width: 1100px) {
						bottom: 25px;
						font-size: 30px;
					}

					@media only screen and (max-width: 840px) {
						bottom: 13px;
					}

					@media only screen and (max-width: 700px) {
						font-size: 26px;
					}

					@media only screen and (max-width: 590px) {
						font-size: 24px;
						top: 50%;
						transform: translateY(-50%);
						bottom: auto;
					}
				}
			}
		}
	}
}

// -----------------------------------//
// TESTIMONIALS
// -----------------------------------//

#testimonials {
	position: relative;
	background: rgba(#003961, 0.9);
	padding: 70px 0 60px 0;
	box-shadow: inset 0px 100px 0px -90px #fff;
	font-family: 'webfontregular', Georgia, serif;
	overflow: hidden;
	min-height: 570px;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/sword-watermark.png);
		height: 577px;
		width: 368px;
		right: 290px;
		top: 5px;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	.wrapper {
		width: 75%;

		h2 {
			margin: 0;
			color: #fff;
			font-size: 26px;

			@media only screen and (max-width: 990px) {
				text-align: center;
			}

			img {
				display: inline;
				margin: 0 25px 0 0;

				@media only screen and (max-width: 990px) {
					display: none;
				}
			}
		}
	}

}

// -----------------------------------//
// NEWS DIARY SEC
// -----------------------------------//

#news-diary-sec {
	position: relative;
	background: rgba(#fff, 0.9);
	padding: 75px 0;

	.wrapper {
		width: 75%;

		h2 {
			color: #E2BE00;
			font-size: 4.427vw;
			text-transform: uppercase;
			margin: 0 0 80px 0;
			text-align: left;

			@media only screen and (max-width: 990px) {
				text-align: center;
				font-size: 45px;
			}

			span {
				display: block;
				font-size: 1.5vw;
				line-height: 0.2;

				@media only screen and (max-width: 990px) {
					font-size: 20px;
					line-height: normal;
				}
			}
		}

	}

	.SideList {
		display: flex;
		padding: 0 15px;
		gap: 15px;
		padding: 0 0 50px 0;
		margin: 0 15px;

		@media only screen and (max-width: 990px) {
			flex-direction: row;
			flex-wrap: wrap;
		}

		@media only screen and (max-width: 700px) {
			flex-direction: column;
			align-items: center;
			margin: 0 25px;
		}

		li {
			width: calc(100% /4 - 10px);
			transition: 0.3s;
			overflow: hidden;

			&:hover {
				background: rgba(0, 0, 0, .03);

				a {

					img {

						filter: brightness(110%);
					}
				}
			}

			@media only screen and (max-width: 990px) {
				width: calc(100% / 2 - 10px);
			}

			@media only screen and (max-width: 700px) {
				max-width: 550px;
				width: 100%;
			}

			a {
				position: relative;
				overflow: hidden;

				img {
					object-fit: cover;
					max-height: 309px;
					overflow: hidden;
					transition: 0.3s;

					@media only screen and (max-width: 700px) {
						margin: 0 auto;
						width: 100%;
					}
				}

				p.date {
					color: #E2BE00;
					font-size: 30px;
					font-weight: lighter;
					margin: 20px 0 0 0;
				}

				p.title {
					font-size: 20px;
					line-height: 30px;
					color: #162E4E;
					font-weight: lighter;

					span {
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}

	.btn-wrap {
		position: relative;
		margin: 0 auto;

		.more {
			display: block;
			font-size: 14px;
			font-weight: bold;
			font-family: 'webfontregular', Georgia, serif;
			width: 270px;
			height: 60px;
			border: 1px solid #003863;
			transition: 0.3s;
			margin: 0 auto;

			&:hover {
				background: #003863;

				span {
					color: #fff;
				}
			}

			span {
				display: block;
				line-height: 60px;
				text-align: center;
				color: #003863;
				text-transform: uppercase;
				font-size: 14px;
			}
		}
	}

}

//NOTICEBOARD PULL OUT TAB

#social {


	#SideHeadingDiary {
		padding: 50px 15px;
		overflow: hidden;
		height: 270px !important;


		ul.SideList {
			display: flex;
			flex-direction: column;


			li {
				border-top: 1px solid rgba(255, 255, 255, .5);
				padding: 10px 0;
				margin: 0 15px 0 0;

				&:nth-of-type(1) {
					border: none;
				}

				p.date {
					color: #E2BE00;
					text-transform: uppercase;
					font-size: 14px;
					font-weight: bold;

					span {}
				}

				p.title {

					span {
						font-size: 18px;
						line-height: 24px;
						font-weight: lighter;
						color: #fff;
						overflow: hidden;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						text-overflow: ellipsis;
					}
				}
			}
		}
	}
}

// -----------------------------------//
// CORE VALUES
// -----------------------------------//

#core-values {
	position: relative;
	background: rgba(#003961, 0.9);
	padding: 70px 0 60px 0;
	font-family: 'webfontregular', Georgia, serif;
	overflow: hidden;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/sword-watermark.png);
		height: 577px;
		width: 368px;
		left: 290px;
		top: 85px;
		transform: scaleX(-1);
		scale: 1.3;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	.wrapper {
		width: 75%;

		h2 {
			margin: 0;
			color: #fff;
			font-size: 26px;
			text-align: right;

			@media only screen and (max-width: 990px) {
				text-align: center;
				font-size: 30px;
			}


			img {
				display: inline;
				margin: 0 0 0 25px;

				@media only screen and (max-width: 990px) {
					display: none;
				}

			}
		}


		ul.values {
			margin: 50px 0 0 0;

			li {
				text-align: right;
				background: none;
				color: #fff;
				font-size: 4.479vw;
				line-height: 1;
				padding: 0;
				margin: 0;

				@media only screen and (max-width: 990px) {
					font-size: 27px;
					text-align: center;
				}
			}
		}
	}
}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	// background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}

	h1,
	h2,
	h3,
	h4 {
		font-weight: bold;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	// background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	h1,
	h2,
	h3,
	h4 {
		font-weight: bold;
	}

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	h1,
	h2,
	h3,
	h4 {
		font-weight: bold;
	}

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		// height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background: #003961;
	border-radius: 15px 2px 15px 2px;
	outline: #003961 solid 3px;
	outline-offset: 3px;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/photos/Gallery/home/010.jpg) center 0 no-repeat;
		height: 100%;
		width: 100%;
		left: 0;
		top: 0;
		opacity: 0.1;
		background-size: cover;
		pointer-events: none;
	}

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
		font-weight: 500;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 4px 20px;
				font-size: 18px;
				color: #fff;
				font-weight: 300;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	// background: #fff url(/i/design/sub-footer.png) right no-repeat;
	background: #fff;
	padding: 50px 0;
	position: relative;
	z-index: 10;
	font-family: 'webfontregular', Georgia, serif;

	@media only screen and (max-width: 1090px) {
		background: #003961;
	}

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/sub-footer.png);
		height: 342px;
		width: 933px;
		right: 0px;
		top: 50%;
		transform: translateY(-50%);

		@media only screen and (max-width: 1650px) {
			right: -180px;
		}

		@media only screen and (max-width: 1240px) {
			right: -340px;
		}

		@media only screen and (max-width: 1090px) {
			display: none;
		}
	}

	.wrapper {
		text-align: left;
		font-size: 18px;
		width: 75%;

		@media only screen and (max-width: 1740px) {
			width: 80%;
		}

		@media only screen and (max-width: 1350px) {
			width: 90%;
		}

		.flex-wrapper {
			display: flex;

			@media only screen and (max-width: 1440px) {
				align-items: center;
			}

			@media only screen and (max-width: 1090px) {
				flex-direction: column;
				align-items: center;
			}

			.left {
				width: 70%;

				@media only screen and (max-width: 1090px) {
					width: auto;
					text-align: center;
				}
			}
		}
	}

	p,
	a[href^="mailto:"] {
		font-size: 1.1vw;
		margin: 0;
		color: #003863;

		@media only screen and (max-width: 1090px) {
			color: #fff;
		}
	}

	// -----------------------------------//
	// SUB-FOOTER
	// -----------------------------------//

	.right {
		font-family: 'Gill Sans MT', Calibri, sans-serif;
		display: flex;
		flex-direction: column;
		align-items: flex-end;

		@media only screen and (max-width: 1090px) {
			align-items: center;
		}

		.trust-logo {
			flex-grow: 0;
			flex-shrink: 0;
			margin: 0 0 15px 0;
		}

		p {
			font-size: 16px;
			line-height: 20px;
			color: #fff;
			text-align: right;
			white-space: nowrap;
			font-weight: 300;

			@media only screen and (max-width: 1090px) {
				white-space: normal;
				text-align: center;
			}
		}

		#copyright,
		#credit {
			font-size: 12px;
			text-align: right;
			color: #fff;
			text-transform: uppercase;
			font-weight: normal;

			@media only screen and (max-width: 1090px) {
				text-align: center;
			}

			a {
				color: #E2BE00;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		#copyright {
			position: relative;
			padding: 10px 0 0 0;
			margin: 30px 0 0 0;

			&:before {
				position: absolute;
				content: "";
				background: rgba(#fff, 0.3);
				height: 1px;
				width: 300px;
				right: 0;
				top: 0;
			}
		}

		#credit {

			a {
				&:nth-of-type(1) {
					color: #fff;
				}
			}
		}
	}


}

h2.footer-heading {
	padding: 0;
	margin: 0;
	color: #E2BE00;
	color: #E2BE00;
	font-size: 4.427vw;
	text-transform: uppercase;

	@media only screen and (max-width: 1090px) {
		font-size: 30px;
	}
}

p.address {
	color: #003863;
	font-size: 1.6vw;
	line-height: 1;

	@media only screen and (max-width: 1090px) {
		color: #fff;
		font-size: 16px;
	}

	span {
		display: block;
		font-size: 1.2vw;
		line-height: 1.3;

		@media only screen and (max-width: 1090px) {
			font-size: 16px;
		}

	}
}

p.telephone {
	margin: 25px 0 0 0;

	@media only screen and (max-width: 1090px) {
		font-size: 16px;
	}
}

footer a[href^="mailto:"] {
	display: block;
	color: #E2BE00;

	@media only screen and (max-width: 1090px) {
		font-size: 16px;
	}

	&:hover {
		text-decoration: underline;
	}
}

p.safeguarding {

	@media only screen and (max-width: 1090px) {
		font-size: 16px;
	}

	a {
		display: inline;
	}
}


// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: transparent;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;