// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_1327 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 730px;

  .backstretch {
    position: fixed !important;
    height: 100vh !important;
  }

  // btm
  &:after {
    @include before(1920px, 229px);
    background: url(/i/design/slider-swoosh.png);
    bottom: 0;
    left: 0;
    z-index: -1;

    @media only screen and (max-width: 990px) {
      @include before(1920px, 450px);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(#162E4E, 0.8) 90%);
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 600px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // top gradient
  &:before {
    @include before(100%, 280px);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0) 90%);
    top: 0;
    left: 0;
  }

  img {
    top: 0px !important;
    min-width: 100% !important;
    min-height: 100% !important;
    object-fit: cover;

    animation: zoom 40s ease forwards infinite;

    @keyframes zoom {
      from {
        transform: scale(1)
      }

      to {
        transform: scale(1.1)
      }
    }
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 200px;
  // right: 0;
  bottom: 60px;
  margin: 0 auto;
  text-align: left;
  color: #fff;
  font-size: 2.4vw;
  line-height: 1;

  @media only screen and (max-width: 990px) {
    left: 0;
    right: 0;
    text-align: center;
    font-size: 30px;
    bottom: 80px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 24px;
  }
}